export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/DSC_0438 (1).jpg" alt="" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">Personal Clave</p>
          <h1 className="skills-section--heading">Miguel Rincón</h1>
          <p className="hero--section-description">
          Como Director Ejecutivo y Fundador, posee una experiencia de más de 3 años en el desarrollo de software, liderando proyectos desde la etapa de análisis del sistema hasta la entrega final a los clientes. Garantiza resultados que cumplen con los más altos estándares de calidad.
          </p>
          <p className="hero--section-description">
          Además, cuenta con una experiencia de 3 años en el sector de Puertas y Ventanas, lo que le otorga una amplia experiencia en este campo. Después de identificar la complejidad de realizar cálculos manuales para un gran número de ventanas, desarrolló la aplicación de desglose (Deg)  con el objetivo de hacer más fácil y rápido el proceso.

          </p>
        </div>
      </div>
    </section>
  );
}
