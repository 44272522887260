
import { Link } from "react-scroll";

export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section1">
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <div className="section--title1"> </div>
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Mirando hacia</span>{" "}
              <br />
              el futuro
            </h1>
            <p className="hero--section-description">
              En Miguel Developer, nos especializamos en simplificar y eficientizar los procesos empresariales a través de soluciones de software innovadoras.
              <br />
            </p>
          </div>

          <div style={{ height: '35px' }}></div>

          <Link
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            to="Contact"
            className="btn btn-primary"
          >
            Contáctenos

          </Link>

        </div>
        <div className="hero--section--img">
          <img src="./img/miguel_x1-removebg-preview.png" alt="Hero Section" id="hero_setion" />
        </div>
      </section>
    </section>
  );
}
