import React, { useState } from 'react';
import axios from 'axios';

const ContactMe = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    topic: '',
    message: '',
    termsAccepted: false
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
 
    
      // await axios.post('/contact', formData);
      alert('¡Mensaje enviado correctamente!');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        topic: '',
        message: '',
        termsAccepted: false
      });
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      alert('Hubo un error al enviar el formulario. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Ponte en Contacto</p>
        <h2>Contáctame</h2>
      </div>
      <form className="contact--form--container" onSubmit={handleSubmit}>
        <div className="container">
          <label htmlFor="first-name" className="contact--label">
            <span className="text-md">Nombre</span>
            <input
              type="text"
              className="contact--input text-md"
              name="firstName"
              id="first-name"  // Corregido: coincidencia con el atributo name
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="last-name" className="contact--label">
            <span className="text-md">Apellido</span>
            <input
              type="text"
              className="contact--input text-md"
              name="lastName"
              id="last-name"  // Corregido: coincidencia con el atributo name
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Correo Electrónico</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="phone-number" className="contact--label">
            <span className="text-md">Número de Teléfono</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phoneNumber"
              id="phone-number"  // Corregido: coincidencia con el atributo name
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <label htmlFor="choose-topic" className="contact--label">
          <span className="text-md">Elige un tema</span>
          <select id="choose-topic" className="contact--input text-md" name="topic" value={formData.topic} onChange={handleChange} required>
            <option value="">Selecciona uno...</option>
            <option value="Interés en productos">Me interesa uno de sus productos</option>
            <option value="Soporte">Soporte</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Mensaje</span>
          <textarea
            className="contact--input text-md"
            id="message"
            name="message"
            rows="8"
            placeholder="Escribe tu mensaje..."
            value={formData.message}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="checkbox" className="checkbox--label">
          <input type="checkbox" required name="termsAccepted" id="checkbox" checked={formData.termsAccepted} onChange={(e) => setFormData({ ...formData, termsAccepted: e.target.checked })} />
          <span className="text-sm">Acepto los términos</span>
        </label>
        <div>
          <button type="submit" className="btn btn-primary contact--form--btn">Enviar</button>
        </div>
      </form>
    </section>
  );
};

export default ContactMe;
