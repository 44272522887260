import data from "../../data/index.json";

export default function Costumers() {
  return (
    <section className="costumers--section" id="Costumers2">
      <div className="portfolio--container">
        <p className="section--title">Clientes</p>
        <h2 className="costumers--section--heading">Nuestros Clientes</h2>
      </div>
      <div className="costumes--section--container1">
        <div className="costumes--section--container">
          {data?.costumers?.map((item, index) => (
            <div key={index} className="costumer--section--card">
              <img src={item.src} alt="Costumeres Chain" className="costumer--section--card-img"/>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
